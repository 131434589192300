<template>
  <span class="relative block">
    <img
      :alt="alt ? alt : `${name} icon`"
      :class="pictogramWrapperClass()"
      :src="pictogramSrc"
    />
  </span>
</template>

<script setup lang="ts">
import AccountFilled from '~/assets/pictograms/static/account-filled.svg?url';
import Account from '~/assets/pictograms/static/account.svg?url';
import Apotek from '~/assets/pictograms/static/apotek.svg?url';
import ArrowHooked from '~/assets/pictograms/static/arrow-hooked.svg?url';
import ArrowLeft from '~/assets/pictograms/static/arrow-left.svg?url';
import ArrowRight from '~/assets/pictograms/static/arrow-right.svg?url';
import ArrowSmall from '~/assets/pictograms/static/arrow-small.svg?url';
import BankId from '~/assets/pictograms/static/bank-id.svg?url';
import Cart from '~/assets/pictograms/static/cart.svg?url';
import Cat from '~/assets/pictograms/static/cat.svg?url';
import CheckMarkAlt from '~/assets/pictograms/static/check-mark-alt.svg?url';
import CheckMarkDark from '~/assets/pictograms/static/check-mark-dark.svg?url';
import CheckMarkGreen from '~/assets/pictograms/static/check-mark-green.svg?url';
import CheckMarkLight from '~/assets/pictograms/static/check-mark-light.svg?url';
import ChevronRight from '~/assets/pictograms/static/chevron-right.svg?url';
import Chevron from '~/assets/pictograms/static/chevron.svg?url';
import ClearSearch from '~/assets/pictograms/static/clear-search.svg?url';
import CrossWithBackground from '~/assets/pictograms/static/cross-with-background.svg?url';
import DealOrange from '~/assets/pictograms/static/deal-orange.svg?url';
import Deal from '~/assets/pictograms/static/deal.svg?url';
import Dog from '~/assets/pictograms/static/dog.svg?url';
import Download from '~/assets/pictograms/static/download.svg?url';
import Edit from '~/assets/pictograms/static/edit.svg?url';
import Filter from '~/assets/pictograms/static/filter.svg?url';
import Frozen from '~/assets/pictograms/static/frozen.svg?url';
import Home from '~/assets/pictograms/static/home.svg?url';
import Info from '~/assets/pictograms/static/info.svg?url';
import LogOut from '~/assets/pictograms/static/log-out.svg?url';
import MenuLegacy from '~/assets/pictograms/static/menu-legacy.svg?url';
import Menu from '~/assets/pictograms/static/menu.svg?url';
import Order from '~/assets/pictograms/static/order.svg?url';
import Payment from '~/assets/pictograms/static/payment.svg?url';
import Pet from '~/assets/pictograms/static/pet.svg?url';
import PickUp from '~/assets/pictograms/static/pick-up.svg?url';
import Pills from '~/assets/pictograms/static/pills.svg?url';
import Prescription from '~/assets/pictograms/static/prescription.svg?url';
import Resource from '~/assets/pictograms/static/resource.svg?url';
import Returns from '~/assets/pictograms/static/returns.svg?url';
import Settings from '~/assets/pictograms/static/settings.svg?url';
import Statistics from '~/assets/pictograms/static/statistics.svg?url';
import Subscriptions from '~/assets/pictograms/static/subscriptions.svg?url';
import VetAccount from '~/assets/pictograms/static/vet-account.svg?url';
import Video from '~/assets/pictograms/static/video.svg?url';
import Warning from '~/assets/pictograms/static/warning.svg?url';
import ServiceListPills from '~/assets/pictograms/static/service-list-pills.svg?url';
import ServiceListVet from '~/assets/pictograms/static/service-list-vet.svg?url';
import ServiceListFlee from '~/assets/pictograms/static/service-list-flee.svg?url';
import ServiceListSupply from '~/assets/pictograms/static/service-list-supply.svg?url';
import InStock from '~/assets/pictograms/static/in-stock.svg?url';
import LowInStock from '~/assets/pictograms/static/low-in-stock.svg?url';
import BackOrder from '~/assets/pictograms/static/back-order.svg?url';

const pictogramSizeClass = {
  xs: 'w-2 h-2',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  ml: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-8 h-8',
  '2xl': 'w-10 h-10',
};

// Map of valid pictogram names to Vue components
const pictograms = {
  'account-filled': AccountFilled,
  account: Account,
  apotek: Apotek,
  'arrow-hooked': ArrowHooked,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-small': ArrowSmall,
  'bank-id': BankId,
  cart: Cart,
  cat: Cat,
  'check-mark-alt': CheckMarkAlt,
  'check-mark-dark': CheckMarkDark,
  'check-mark-green': CheckMarkGreen,
  'check-mark-light': CheckMarkLight,
  'chevron-right': ChevronRight,
  chevron: Chevron,
  'clear-search': ClearSearch,
  'cross-with-background': CrossWithBackground,
  'deal-orange': DealOrange,
  deal: Deal,
  dog: Dog,
  download: Download,
  edit: Edit,
  filter: Filter,
  frozen: Frozen,
  home: Home,
  info: Info,
  'log-out': LogOut,
  'menu-legacy': MenuLegacy,
  menu: Menu,
  order: Order,
  payment: Payment,
  pet: Pet,
  'pick-up': PickUp,
  pills: Pills,
  prescription: Prescription,
  resource: Resource,
  returns: Returns,
  settings: Settings,
  statistics: Statistics,
  subscriptions: Subscriptions,
  'vet-account': VetAccount,
  video: Video,
  warning: Warning,
  'service-list-pills': ServiceListPills,
  'service-list-vet': ServiceListVet,
  'service-list-flee': ServiceListFlee,
  'service-list-supply': ServiceListSupply,
  'in-stock': InStock,
  'low-in-stock': LowInStock,
  'back-order': BackOrder,
};

interface Props {
  name: keyof typeof pictograms;
  size?: keyof typeof pictogramSizeClass;
  alt?: string;
  customClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  alt: undefined,
  customClass: undefined,
});

const pictogramWrapperClass = () => {
  if (props.customClass) {
    return props.customClass;
  }
  const sizeClass = pictogramSizeClass[props.size];
  return `pictogram ${sizeClass}`;
};

// Select the correct pictogram source based on the name prop
const pictogramSrc = computed(() => pictograms[props.name]);

export type { Props, pictogramSizeClass };
</script>

<style lang="postcss">
.pictogram svg {
  @apply h-full w-full;
}
</style>
